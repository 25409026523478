<template>
  <v-container
    grid-list-md
    pa-0
    relative
    class="page-layout marks_entry_individual"
  >
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Individual Marks Entry
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :loading="examLoading"
                  :disabled="!exams.length"
                  :items="exams"
                  class="pa-0"
                  label="Exam"
                  v-model="exam"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs8 sm8>
                <v-student-search-field
                  :disabled="!exam"
                  @onSelectStudent="selectedStudent"
                ></v-student-search-field>
              </v-flex>
            </v-card-title>
          </v-card>

          <v-container v-if="Object.keys(student).length">
            <v-student-semi-profile :student="student" />
          </v-container>

          <div v-if="form.items.data.length" class="data-represent">
            <div>
              <span class="d-info"></span> &nbsp;
              <strong>{{ form.items.data.length }}</strong>
              <small> Total Subjects</small>
            </div>
            <div>
              <span class="d-error"></span> &nbsp;
              <strong>{{ absentStudentsCount }}</strong>
              <small> Absent</small>
            </div>

            <div
              class
              style="float: right;margin-left: auto;margin-right: 40px;color:#f48e52;"
            >
              <strong>
                <v-icon small color="#f48e52">check_box</v-icon>
                Check to mark Absent.
              </strong>
              <!--<strong style="color:#ff5252" v-if="!verify_status.is_verify && !marks_entry.allowed">-->
              <!--<v-icon small color="#ff5252">close</v-icon>-->
              <!--Marks entry is not allowed until {{marks_entry.duration.start}}.-->
              <!--</strong>-->

              <!--<strong-->
              <!--v-if="verify_status.is_verify===1"-->
              <!--style="color:#777;font-size: 14px;font-weight: normal"-->
              <!--&gt;-->
              <!--<v-icon small color="#4caf50">check_circle</v-icon>-->
              <!--Marks Verified by-->
              <!--<strong style="color:#444;">{{verify_status.details.verified_by}}</strong>-->
              <!--</strong>-->
            </div>
          </div>

          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="itemData"
            :loading="form.loading"
            class="marks_entry"
          >
            <template v-slot:item="{ index, item }">
              <tr :class="''">
                <td class="text-xs-center">{{ index + 1 }}</td>
                <td class="text-xs-left">
                  <strong
                    style="font-size: 16px;display: block;margin-top: 15px;"
                    >{{ item.name }}</strong
                  >
                  <p style="font-size: 12px;color: #777;">
                    Theory
                    <strong style="font-weight: normal">{{
                      item.theory
                    }}</strong
                    >,<span style="padding-left: 5px;"></span> &nbsp;&nbsp;
                    Practical
                    <strong style="font-weight: normal">{{
                      item.practical
                    }}</strong>
                  </p>
                </td>
                <td class="text-right">
                  <div style="float: right" class="marks_entry_input_container">
                    <strong v-if="item.is_verified">{{ item.oth }}</strong>
                    <input
                      v-if="
                        !item.is_verified &&
                          $auth.can('marks-input-create&marks-input-update')
                      "
                      class="mark_entry_input"
                      type="text"
                      v-model="item.oth"
                      @focus="$event.target.select()"
                      :disabled="item.oth === 'Absent'"
                      @keyup="
                        (changed = true), checkTheoryVal($event, item, index)
                      "
                    />
                    <v-checkbox
                      v-if="
                        !item.is_verified &&
                          $auth.can('marks-input-create&marks-input-update')
                      "
                      @change="
                        (changed = true),
                          updateMarksAbsentStatus(index),
                          disabledSubmit()
                      "
                      v-model="item.oth"
                      value="Absent"
                      color="error"
                    ></v-checkbox>
                  </div>
                </td>
                <td class="text-right">
                  <div style="float: right" class="marks_entry_input_container">
                    <strong v-if="item.is_verified">{{ item.op }}</strong>

                    <input
                      v-if="
                        !item.is_verified &&
                          $auth.can('marks-input-create&marks-input-update')
                      "
                      class="mark_entry_input"
                      type="text"
                      @focus="$event.target.select()"
                      v-model="item.op"
                      :disabled="
                        item.practical <= 0 || itemData[index].op === 'Absent'
                      "
                      @keyup="
                        (changed = true), checkPracticalVal($event, item, index)
                      "
                    />
                    <v-checkbox
                      v-if="
                        !item.is_verified &&
                          $auth.can('marks-input-create&marks-input-update')
                      "
                      @change="
                        (changed = true),
                          updateMarksAbsentStatus(index),
                          disabledSubmit()
                      "
                      v-model="item.op"
                      :disabled="item.practical <= 0"
                      value="Absent"
                      color="error"
                    ></v-checkbox>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>

          <br />

          <v-card-actions>
            <v-btn
              :loading="saving"
              large
              block
              message="Are you sure you want to un verify the verified entry."
              color="primary"
              v-if="
                !verify_status.is_verify &&
                  form.items.data.length > 0 &&
                  $auth.can('marks-input-create&marks-input-update')
              "
              :outlined="false"
              :disabled="!dataVerfiy || saving || !changed"
              @click="save"
            >
              {{ form.is_update ? "Update" : "Save" }}
            </v-btn>
            <!--<v-spacer></v-spacer>-->
            <!--<v-btn color="success"-->
            <!--v-if="$auth.can('marks-input-create&marks-input-update')"-->
            <!--outlined-->
            <!--:disabled="dataVerfiy"-->
            <!--@click="save">Save-->
            <!--</v-btn>-->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

export default {
  data: () => ({
    examLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    changed: false,
    verify_status: {},
    saving: false,
    student: {},
    form: new Form(
      {
        grade_id: "",
        section_id: "",
        exam_id: "",
        marks: [],
        enroll_id: "",
      },
      "/api/marksheet-individual"
    ),
    search: null,
    pagination: {
      rowsPerPage: 100,
      sortBy: "roll",
    },
    rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
    is_verify: 0,
    dataVerfiy: false,
    subject: "",
    subjects: [],
    section: "",
    sections: [],
    enroll_id: "this",
    entries: [],
    exam: "",
    exams: [],
    grade: "",
    grades: [],
    subject_marks: {},
    verified_subjects: [],
    headers: [
      { text: "#", align: "center", value: "roll", width: 50, sortable: false },
      { text: "Subject", align: "left", value: "name", sortable: false },
      { text: "Theory", align: "right", value: "theory", sortable: false },
      {
        text: "Practical",
        align: "right",
        value: "practical",
        sortable: false,
      },
    ],
    itemData: [],
    // itemData : [
    //     {subject:'English',theory:80,practical:20,oth:'',op:''},
    //     {subject:'Nepali',theory:100,practical:0,oth:'',op:''},
    //     {subject:'Science',theory:75,practical:25,oth:'',op:''},
    //     {subject:'Social',theory:100,practical:0,oth:'',op:''},
    // ],
    evaluation: "",
  }),
  computed: {
    ...mapState(["batch"]),

    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
    selectedEvaluation() {
      let grade = this.grade;
      let output = "";
      this.grades.map(function(item) {
        if (grade === item.value && item.evaluation) {
          output = "Evaluation " + item.evaluation + "%";
        }
      });
      return output;
    },
    absentStudentsCount() {
      let count = 0;
      this.form.items.data.map((item) => {
        if (
          item.oth === "Absent" ||
          item.op === "Absent" ||
          item.theory === 9999
        ) {
          count++;
        }
      });
      return count;
    },
    verifiedSubjectIds() {
      return this.verified_subjects.map((item) => {
        return item.subject_id;
      });
    },
  },
  mounted() {
    this.getExams();
  },
  watch: {
    batch: function(value) {
      this.get();
    },
    exam: function() {
      // this.grade = '';
      // this.enroll_id = '';
      this.itemData = [];
      // this.entries = [];
      this.get();
      // this.getGrades();
    },
    section: function() {
      // this.enroll_id = '';
      // this.entries = [];
      // this.get();
    },
    search(val) {
      if (!val) return;
      // if (this.isLoading) return;

      this.isLoading = true;
      this.$rest
        .get(
          "/api/search-student?rowsPerPage=25&page=1&sortBy=name&totalItems=6&sectionId=" +
            this.section +
            "&search=" +
            val +
            "&gradeId=" +
            this.grade +
            "&slim=true"
        )
        .then(({ data }) => {
          this.entries = data.data.map((res) => {
            return { name: res.name, id: res.enroll_id };
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&") +
        "&gradeId=" +
        this.grade.toString() +
        "&examId=" +
        this.exam.toString() +
        "&sectionId=" +
        this.section.toString() +
        "&enrollId=" +
        this.enroll_id
      );
    },

    selectedStudent(student) {
      if (student) {
        this.student = student;
        this.section = student.details.section_id;
        this.enroll_id = student.details.enroll_id;
        this.grade = student.details.grade_id;
        this.get();
      }
    },
    get(params) {
      if (this.section && this.enroll_id) {
        let query = [null, undefined].includes(params)
          ? this.queryString()
          : params;
        query = query.replace("&descending=false&page=1", "");
        this.form.get(null, query).then(({ data }) => {
          this.dataVerfiy = false;
          this.changed = false;
          this.verified_subjects = data.verified_subjects;
          const verifiedSubjectIds = this.verified_subjects.map((item) => {
            if (item.is_verify) return item.subject_id;
            return null;
          });
          this.itemData = data.data.map(function(item) {
            if (verifiedSubjectIds.indexOf(item.subject_id) > -1) {
              item.is_verified = true;
            }
            return item;
          });
        });
      }
    },

    save() {
      this.form.marks = this.itemData.map(function(item) {
        if (item.oth === "") {
          item.oth = 0;
        }
        if (item.op === "") {
          item.op = 0;
        }

        return item;
      });

      this.form.grade_id = this.grade;
      this.form.exam_id = this.exam;
      this.form.section_id = this.section;
      this.form.enroll_id = this.enroll_id;
      this.saving = true;
      this.form
        .store()
        .then(() => {})
        .finally((res) => {
          this.saving = false;
          this.changed = false;
        });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/exam-grade?rowsPerPage=25&sortBy=rank&descending=true&slim=true&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              value: item.grade_id,
              text: item.name,
              evaluation: item.evaluation,
            };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getExams() {
      this.examLoading = true;
      this.$rest
        .get("/api/exam")
        .then(({ data }) => {
          this.exams = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.examLoading = false;
        });
    },
    getSections() {
      this.$rest
        .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    getSubjects() {
      this.subjectLoading = true;
      this.$rest
        .get(
          "/api/mark-subject-list?rowsPerPage=20&gradeId=" +
            this.grade +
            "&examId=" +
            this.exam
        )
        .then(({ data }) => {
          this.subjects = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.subjectLoading = false;
        });
    },
    editData(subject) {
      this.subject = subject;
    },
    checkPracticalVal(e, value, i) {
      this.disabledSubmit();
      if (parseFloat(value.op) > parseFloat(value.practical)) {
        e.target.style.border = "1px solid red";
        e.target.style.color = "red";
        this.itemData[i].op = parseFloat(value.practical) + 1;
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },
    checkTheoryVal(e, value, i) {
      this.disabledSubmit();
      if (parseFloat(value.oth) > parseFloat(value.theory)) {
        e.target.style.border = "1px solid red";
        e.target.style.color = "red";
        this.itemData[i].oth = parseFloat(value.theory) + 1;
      } else {
        e.target.style.border = "1px solid #ccc";
        e.target.style.color = "black";
      }
    },
    disabledSubmit() {
      let count = this.itemData.length;
      let j = 0;
      let i = 0;
      this.itemData.map((res) => {
        if (res.oth <= res.theory || res.oth === "Absent") {
          j++;
        }
      });
      this.itemData.map((res) => {
        if (res.op <= res.practical || res.op === "Absent") {
          i++;
        }
      });
      this.dataVerfiy = !(j !== count || i !== count);
    },
    updateMarksAbsentStatus(index) {
      if (this.itemData[index].oth === null) {
        this.itemData[index].oth = 0;
      }

      if (this.itemData[index].op === null) {
        this.itemData[index].op = 0;
      }
    },
  },
};
</script>
<style lang="scss">
.marks_entry_individual {
  .v-input--selection-controls__input {
    margin-top: 10px;
  }
}

.marks_entry {
  input[disabled] {
    background-color: #eee;
    color: #ccc !important;
    border-color: #ccc !important;
    cursor: not-allowed;
  }

  input[value="0"] {
    color: #ccc !important;
    border-color: #ccc !important;
  }

  .mark_entry_input {
    border: 1px solid #ccc;
    font-weight: bold;
    border-radius: 3px;
    width: 60px;
    height: 25px;
    text-align: center;
  }
  .marks_entry_input_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -5px;
    .v-input--selection-controls {
      margin-right: 0 !important;
    }
    .v-application--is-ltr .v-input--selection-controls__input {
      margin-right: 0 !important;
    }

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input__control {
      display: -webkit-box !important;
      -webkit-box-orient: horizontal !important;
    }

    .v-input {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      display: -webkit-box !important;
      display: -ms-flexbox;
      /* display: flex; */
      /* -webkit-box-flex: 1; */
      -ms-flex: 1 1 auto;
      flex: none;
      font-size: 16px;
      text-align: left;
    }
  }

  input:focus {
    outline: none;
  }
}

.v-input--selection-controls {
  margin-right: 20px !important;
  flex: none !important;
}

.marks_entry_individual .v-input--selection-controls__input {
  margin-top: 0 !important;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>
